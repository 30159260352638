@charset "UTF-8";

header,
nav,
footer,
section,
aside {
  display: block;
  position: relative;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #162f5e;
  background: url("images/slide-1.jpg") no-repeat bottom center / cover;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  line-height: 1.5em;
  color: #fff;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
#wrap {
  position: relative;
}
#wrap::before {
  content: "";
  display: block;
  /* background: linear-gradient(to bottom,  #162f5e 0%,#0f1642 100%); */
  background: linear-gradient(
    to bottom,
    rgba(22, 47, 94, 0) 0%,
    rgba(17, 28, 73, 0.85) 0%,
    rgba(15, 22, 66, 0.85) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#ccl-logo {
  display: inline-block;
  max-width: 250px;
  margin: auto;
}
#package-info article {
  background: rgba(0, 0, 0, 0.2);
  padding: 20px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}
#package-info h3 {
  text-transform: uppercase;
}
#package-info h3 small {
  text-transform: capitalize;
}
#package-info p {
  font-size: 22px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  #package-info p {
    font-size: 19px;
  }
}

#pay-form {
  max-width: 850px;
  margin: auto;
  padding: 15px;
}
#pay-form h3 {
  color: #d3a345;
  margin: 15px 0 10px;
}
#pay-form .form-control {
  background: rgba(255, 255, 255, 0.75);
  height: auto;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 20px;
}
#pay-form .form-control:focus {
  background: rgba(255, 255, 255, 1);
}
#pay-form label {
  font-size: 15px;
  font-weight: 600;
  margin: 12px 0 3px 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  transition: all 0.5s;
}

h1 {
  color: #fff;
  font-size: 58px;
  font-weight: 300;
  line-height: 1;
  margin: 0;
  letter-spacing: 0px;
  transition: all 0.5s;
}
@media screen and (max-width: 992px) {
  h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 38px;
  }
}

h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  margin: 15px 0px 20px;
  transition: all 0.5s;
}
@media screen and (max-width: 992px) {
  h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2em;
  margin: 7px 0px;
  transition: all 0.5s;
}
h3 small {
  display: block;
  font-size: 75%;
}
@media screen and (max-width: 992px) {
  h3 {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-size: 19px;
  font-weight: 500;
  line-height: 1;
  margin: 15px 0px;
  transition: all 0.5s;
}

#main {
  position: relative;
  overflow: hidden;
}

a.link,
a.link:visited {
  color: #162f5e;
  text-decoration: none;
  outline: 0;
  border: 0;
  transition: color 0.5s;
}
a.link:hover {
  color: #162f5e;
  outline: 0;
  border: 0;
  text-decoration: none;
}

a.link-white,
a.link-white:visited {
  color: #fff;
  text-decoration: none;
  outline: 0;
  border: 0;
  transition: color 0.5s;
}
a.link-white:hover {
  color: #d3a345;
  outline: 0;
  border: 0;
  text-decoration: none;
}

a.link-gold,
a.link-gold:visited {
  color: #d3a345;
  text-decoration: none;
  outline: 0;
  border: 0;
  transition: color 0.5s;
}

a.link-golf:hover {
  color: #d3a345;
  outline: 0;
  border: 0;
  text-decoration: underline;
}

.white {
  color: #fff;
}

.bg-white {
  background-color: #f7f7f7;
}

.bg-white * {
  color: #070606;
}

.lt-gray {
  color: #f7f7f7;
}

.bg-lt-gray {
  background-color: #f7f7f7;
}

.bg-lt-gray * {
  color: #070606;
}

.gray {
  color: #eee;
}

.bg-gray {
  background-color: #eee;
}

.bg-gray * {
  color: #070606;
}

.dk-gray {
  color: #111;
}

.bg-dk-gray {
  background-color: #111;
}

.bg-dk-gray * {
  color: #fff;
}

.black {
  color: #070606;
}

.bg-black {
  background-color: #070606;
}

.bg-black * {
  color: #fff;
}

.gold {
  color: #d3a345;
}

.bg-gold {
  background-color: #d3a345;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bttn,
.bttn:visited,
#btnSubmit {
  display: inline-block;
  background-color: #d3a345;
  background-size: 100%;
  background-image: linear-gradient(45deg, transparent, transparent);
  border-radius: 32px;
  color: #f8f8f8;
  font-weight: 600;
  text-align: center;
  font-size: 19px;
  text-transform: uppercase;
  min-width: 225px;
  padding: 16px 35px;
  border: 2px solid #d3a345;
  box-shadow: 0px 5px 9.5px 0.5px rgba(0, 0, 0, 0.15);
  transition: 0.2s all ease-in-out;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.bttn:hover,
#btnSubmit:hover {
  display: inline-block;
  background-color: #bd913b;
  color: #f8f8f8;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

footer {
  color: #fff;
  font-size: 14px;
  margin-top: 50px;
  clear: both;
  position: relative;
}

footer p {
  color: #fff;
}

#copyright {
  background: #0f1642;
  margin-top: 15px;
}

#copyright p {
  color: rgb(255, 255, 255);
  font-size: 11px;
  margin: 10px 0;
}

#copyright a.link {
  color: rgb(255, 255, 255);
  transition: color 1s;
}

#copyright a.link:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
